import React from "react";
import { snakeCase } from "lodash-es";

import { MapKind } from "components";
import { MAP_NAVIGATOR_SORTING, MapNavigatorSorting, useLocales } from "hooks";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ReactComponent as SortIcon } from "../../../icons/sort.svg";

export interface MapNavigatorSortHeaderProps {
  type: MapKind;
  sorting: MapNavigatorSorting;
  onSortingChange: (sorting: MapNavigatorSorting) => void;
}

export function MapNavigatorSortHeader({
  type,
  sorting,
  onSortingChange,
}: MapNavigatorSortHeaderProps): JSX.Element {
  const { t } = useLocales();

  function getSortingLabel(sorting: MapNavigatorSorting): string {
    if (sorting === MapNavigatorSorting.Level) {
      return t(`navigator.sort_${type === "warnMap" ? "level" : "category"}`);
    }
    return t(`navigator.sort_${snakeCase(sorting)}`);
  }

  return (
    <div className="navigator-sort">
      <SortIcon />
      <label htmlFor={`navigator-sort-${type}`} className="sr-only">
        {t("navigator.sort")}
      </label>
      <select
        id={`navigator-sort-${type}`}
        className="form-control"
        onChange={(e) => onSortingChange(e.target.value as MapNavigatorSorting)}
        defaultValue={sorting}
      >
        {MAP_NAVIGATOR_SORTING.map((s) => (
          <option key={s} value={s}>
            {getSortingLabel(s)}
          </option>
        ))}
      </select>
    </div>
  );
}
